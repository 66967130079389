.about-page-container {
    max-width: 1200px;
    margin: auto;
    padding: 20px;
    background-color: #2D4371;
    font-family: 'Nunito', sans-serif !important;
  }


.team-introduction {
    color: white !important;
}

.main-heading,
.team-name {
    color: white;
    border: 10px;
}

  .box-container {
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4, 1fr); /* Creates 4 columns */
    grid-gap: 20px;
    justify-items: center; /* Center each box horizontally */
    background-color: #2D4371 !important;
  }
  
  .box {
    font-weight: bold;
    background-color: #ccc; /* Light grey background */
    border-radius: 10px;
    padding: 15px;
    border: 1px solid #ccc;
    width: 80%; /* Full width of the column */
    height: auto; /* Adjust height based on content */
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    box-shadow: 0 2px 5px rgba(0,0,0,0.1); /* Adds shadow for better visibility */
    margin-bottom: 20px; /* Space between rows */
  }
  
  .box-image {
    width: 100%; /* Slightly less than full width for padding */
    height: auto; /* Maintain aspect ratio */
    max-height: 150px; /* Limit image height */
    object-fit: cover;  /* Cover the container without distorting the image */
  object-position: center;
  transition: transform 0.3s ease;
  border-radius:50px;
  }

  .box:hover .box-image {
    transform: scale(1.5); /* Adjust scale to desired zoom level */
  }
  
  .image-description {
    text-align: center;
    margin-top: 10px;
  }

  /* Custom alignment for the last row */
.box-container::after {
    content: "";
    width: 0;
    grid-column: 1 / span 4; /* Takes full width */
    visibility: hidden;
  }
  
  @media (max-width: 800px) {
    .box-container {
      grid-template-columns: repeat(2, 1fr); /* 3 columns for smaller screens */
    }
  }
  
  .modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0,0,0,0.5);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .modal-content {
    background: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 15px rgba(0,0,0,0.2);
    width: 50%;
    max-width: 600px;
  }
  
  .close-button {
    float: right;
    font-size: 1.5em;
    cursor: pointer;
  }
  