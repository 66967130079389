/* Chat window styling */
  .chat-window {
    flex-grow: 1;
    padding: 30px;
    overflow-y: auto;
    height: calc(100vh - 170px);
    display: flex;
    flex-direction: column;
    gap: 10px;
    background-color: #355997;
    white-space: pre-wrap; /* Preserves whitespace and wraps text */
    word-wrap: break-word; /* Prevents long text strings from overflowing */
  }

  .chat-input {
    display: flex;
    align-items: center;
    border-left: 0px;
    flex-grow: 1;
    padding: 10px;
    border-top-left-radius: 20px; /* Only the top-left corner will be rounded */
    border-bottom-left-radius: 20px; /* Only the bottom-left corner will be rounded */
    margin-left: 40px;
    margin-right: 30px;
    background-color: #26292f; /* The dark blue background */
    color: rgb(213, 204, 204); /* Text color */
    overflow-y: auto; /* Scrollable vertically */
    resize: none; /* Disable resizing */
    max-height: 200px; /* Maximum height */
    line-height: 1.5; /* Line height for readability */
    box-sizing: border-box; /* Padding included in the width and height */
    font-weight: 550;
    font-family: Nunito; 
    font-size: 16px;
  }

  .loading-bar {
    height: 20px;
    background-color: #ccc;
    width: 100%;
    text-align: center;
    padding: 10px;
    font-size: 16px;
    color: #333;
}


  .chat-input-button {
    padding: 20px 20px;
    background-color: #f2ecec !important; 
    color: black; /* Text color */
    border: none; /* Removes the border */
    border-top-right-radius: 20px; /* Only the top-right corner will be rounded */
    border-bottom-right-radius: 20px; /* Only the bottom-right corner will be rounded */
    cursor: pointer; /* Indicates it's clickable */
    margin-right: 10px;
    font-family: Nunito;
    font-weight: 550;
    font-size:20px;
  }
  
  .chat-input-button:hover {
    background-color: #e4c2d2; /* Lighter pink color on hover */
  }
  
  .reset-button {
    background-color: rgb(242, 110, 172);
    margin-left: 10px; /* Adjust spacing as needed */
}
.save-conversation-button {
  background-color: rgb(199, 175, 186);
  margin-left: 10px; /* Adjust spacing as needed */
}
  
@keyframes dots {
  0%, 20% {
      color: white;
      font-size: 30px;
  }
  40% {
      color: grey;
      font-size: 30px;
  }
  60% {
      color: grey;
      font-size: 30px;
  }
  80%, 100% {
      color: white;
      font-size: 30px;
  }
}

.loading-message {
  font-size: 20px;
  color: white;
  font-family: Nunito;
  text-align: left;
}

.loading-message::after {
  content: '...';
  animation: dots 1.5s infinite;
  width: 1ch;
}

button {
  font-family: 'Nunito', sans-serif; /* Add fallback font */
}

.user-input{
  margin-left: 10px;
  padding-left: 10px;
  background-color: rgb(213, 204, 204);
}

.chat-bot-form {
  background-color: transparent;
  position: relative;
  bottom: 0;        /* Aligns the element at the bottom of the page */
  left: 0;          /* Aligns the element to the left */
  transform: translateY(80px);
}
