.image-upload-container {
    padding: 20px;
    background-color: #f0f0f0;
    border-radius: 8px;
    width: 90%;
    margin: 20px auto 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.image-upload-container h3 {
    margin-bottom: 15px;
    font-size: 1.5rem;
    color: #333;
    text-align: center;
}

.upload-controls {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.upload-controls input[type="file"] {
    margin-right: 10px;
}

.image-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.image-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    padding: 10px;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    max-width: 200px;
    width: 100%;
    height: 300px; /* Set a fixed height to align items at the bottom */
    text-align: center;
}

.image-item img {
    max-width: 100%;
    border-radius: 4px;
    margin-bottom: auto; /* Push the image to the top */
}

.image-item .info-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: flex-end; /* Align at the bottom */
}

.image-item p {
    margin: 0;
    font-size: 0.9rem;
    color: #555;
}

.delete-button {
    padding: 5px 10px;
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.delete-button:hover {
    background-color: #c82333;
}

.file-input {
    width: 5px; /* Set the desired width */
    height: 40px; /* Set the desired height */
    padding: 5px; /* Adjust padding for a more compact look */
    font-size: 0.9rem; /* Adjust font size to fit the smaller input */
    border-radius: 4px; /* Optional: add border-radius for better aesthetics */
    border: 1px solid #ccc; /* Optional: define border style */
    box-sizing: border-box; /* Ensure padding and border are included in the width and height */
}

.file-input::-webkit-file-upload-button {
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.file-input::-webkit-file-upload-button:hover {
    background-color: #0056b3;
}
