@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;700&display=swap');
/* General resets */
/* Global page elements */
body, html {
  margin: 0;
  padding: 0;
  font-family: 'Nunito', sans-serif;
  background-color: #f2f2f2; /* Set the background color for the whole page */
}

/* Headings and labels */
.phase-label {
  font-size: 2.0rem; /* Example size */
  color: rgb(246, 201, 92); /* Example color */
  margin-left: 20px; /* Align with the form containers */
  font-family: 'Nunito', sans-serif;
  font-weight: 600 !important; 
  margin-top: 70px;
  margin-bottom: 10px;
}

/* Apply shared styles with a base class */
.form-container {
  /*max-width: 1200px;*/
  margin: 2% auto;
  padding: 1%;
  background-color: #f2f2f2; /* Choose your preferred color */
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  width: 90%; 
  max-width: 55.5%;
}



/* Container styling */
.profileContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  resize:none;
}

/* Container styling */
.scenarioContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  resize:none;
}



/* Container styling */
.objectivesContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  resize:none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* adjusts number of columns based on available space */
  gap: 50px;
}

/* Container styling */
.objectivesContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  resize:none;
}

/* Container styling */
.patientInfoContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  resize:none;
}

/* Container styling */
.historyContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  resize:none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* adjusts number of columns based on available space */
  gap: 50px;
}

/* Container styling */
.PMHContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  resize:none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* adjusts number of columns based on available space */
  gap: 50px;
}

/* Container styling */
.initialExamContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  resize:none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* adjusts number of columns based on available space */
  gap: 50px;
}

/* Container styling */
.labContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  resize:fixed;
}

/* Container styling */
.phaseContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  /* resize:none; */
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* adjusts number of columns based on available space */
  gap: 50px;
}



/* Container styling */
.stopContainer {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  background-color: #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  overflow-y: auto;
  resize:none;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* adjusts number of columns based on available space */
  gap: 50px;
}

/* Title styling */
.patientReportTitle {
  font-size: 24px; /* Adjust as needed */
  margin: 0; /* Adjust as needed */
  padding: 10px 0; /* Adjust as needed */
  text-align: center; /* Optional: center the title */
}

/* Form group styling */
.form-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
}

.profile-group {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 10px;
}

label {
  display: block;
  margin-bottom: 5px;
  color: #333;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
}


input[type="text"]:focus,
input[type="number"]:focus,
textarea:focus {
  border-color: #007bff;
  outline: none;
}

/* Button styling */
.btn-primary {
  background-color: #e9a0a5;
  color: black;
  padding: 10px 15px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
  margin-left: 525px;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;

}

.btn-primary:hover {
  background-color: #efd6d8;
}

/* Utility classes */
.hidden {
  display: none;
}

.profile-input {
  width: 50%; /* Responsive width */
  height: 40px; /* Fixed height */
  padding: 0 10px; /* Padding inside the input */
  border: 1px solid #ccc; /* Border styling */
  border-radius: 10px; /* Rounded corners */
  resize: none;
  flex: 1 0 180px; /* Flex-grow | Flex-shrink | Flex-basis */
  transition: background-color 0.3s ease, color 0.3s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
  .profile-input:hover {
    transform: scale(1.05);
    box-shadow: 0 2px 4px rgb(74, 73, 73) !important;
  }

.case-input {
  width: 65%;
  height: 150px;
  margin: 10px 5px;
  border: 1px solid #ccc;
  border-radius: 10px; 
  flex: 1 0 180px; /* Flex-grow | Flex-shrink | Flex-basis */
  transition: background-color 0.3s ease, color 0.3s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px !important;
}

.case-input:hover {
  transform: scale(1.05); /* Slightly enlarge the avatar */
  box-shadow: 0 2px 4px rgb(74, 73, 73) !important;
}

.case-input-long {
  width: 328%;
  height: 150px;
  margin: 10px 5px;
  border: 1px solid #ccc;
  border-radius: 10px; 
  flex: 1 0 180px; /* Flex-grow | Flex-shrink | Flex-basis */
  transition: background-color 0.3s ease, color 0.3s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px !important;
}

.case-input-long:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 4px rbg(74, 73, 73) !important;
  margin: 10px 5px !important;
}

.button-container {
  display: flex;
  justify-content: flex-start; /*This will push the button to the right */
  padding-right: 20px; /* Optional padding on the right */
}

.phaseButton {
  font-family: 'Nunito', sans-serif; 
  font-weight: bold;
  margin-left: 30px;
}

.case-input-long {
  width: 328%;
  height: 150px;
  margin: 10px 5px;
  border: 1px solid #ccc;
  border-radius: 10px; 
  flex: 1 0 180px; /* Flex-grow | Flex-shrink | Flex-basis */
  transition: background-color 0.3s ease, color 0.3s ease;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 10px !important;
}

.case-input-long:hover {
  transform: scale(1.05);
  box-shadow: 0 2px 4px rbg(74, 73, 73) !important;
  margin: 10px 5px !important;
}