/* Navigation links */

nav {
    display: flex;
  justify-content: space-between; /* Ensures items are spaced between logo and navigation links */
  align-items: center; /* Align items vertically in the center */
  width: 100%;
}
.navigation-links {
  color: #000; /* Black color for the text */
  text-decoration: none;
  font-size: 1em;
  background-color: #2D4371;
  padding: 0 10px; /* Add padding as needed */
  align-items: center; /* Align items vertically */
  display: flex;
  flex: 0 1 0%;  /* Takes up remaining space, allows shrinking and growing */
  justify-content: flex-end;  /* Aligns children (buttons) to the end (right) */
  gap: 10px

}



/* Active class for navigation links */
.navigation-links.active {
  background-color: #FF1493; /* Pink color for the active link */
}

.home-button {
    background-color: #2D4371;
    color: white;
    font-family: 'Nunito', sans-serif;
    font-weight: 550;
    cursor: pointer;
    padding: 8px 15px;
    min-width: 100px;
    flex-grow: 1;
  text-align: center;
  border-radius: 5px;
}

.home-button:hover {
    transform: scale(1.1) !important;
    background-color: #2D4371;
    transition: transform 0.3s ease;
}

.product-button {
    background-color: #2D4371;
    color: white;
    font-family: 'Nunito', sans-serif;
    font-weight: 550;
    cursor: pointer;
    padding: 8px 15px;
    border: none;
    flex-grow: 1;
  text-align: center;
  min-width: 100px;
}

.product-button:hover {
    transform: scale(1.1) !important;
    background-color: #2D4371;
    transition: transform 0.3s ease;
}

.about-us-button {
    background-color: #2D4371;
    color: white;
    font-family: 'Nunito', sans-serif;
    font-weight: 550;
    cursor: pointer;
    padding: 8px 15px;

    flex-grow: 1; /* Buttons grow evenly */
  text-align: center;
  min-width: 100px;
}

.about-us-button:hover {
    transform: scale(1.1) !important;
    background-color: #2D4371;
    transition: transform 0.3s ease;
}

.sign-in-button {
    font-family: 'Nunito', sans-serif;
    font-weight: 550;
    padding: 8px 15px;
    margin: 15px;
    background-color: #e9a0a5;
    color: white ;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    min-width: 80px;

    
}

.sign-in-button:hover {
    transform: scale(1.1) !important;
    transition: transform 0.3s ease;
}

.logo {
    background-color: #2D4371;
    font-size: 32px;
    font-family: 'Nunito', sans-serif;
    font-weight: bold;
    color: white;


    flex: 0 1 auto; /* Prevent logo from shrinking */
  max-width: 200px; /* Maximum width of logo */
  /* margin-right: auto; */
; 
}

.logo:hover{
    background-color: #2D4371;
}


@media (max-width: 768px) {
    .logo {
      font-size: 24px; /* Smaller font size for smaller screens */
      margin-right: 0; /* Adjust margin */
    }
    .home-button, .product-button, .about-us-button, .sign-in-button {
      padding: 5px 10px; /* Smaller padding */
      font-size: 0.8em; /* Smaller font size */
    }
  }